@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(MaterialIcons-Regular.woff2) format('woff2'),
      url(MaterialIcons-Regular.woff) format('woff'),
      url(MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  margin-top: -2px;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align: middle;
}
.md-14{
  font-size:14px;
}