@media (max-width: 768px) {
    .header.m-5{
        margin: 1em .3em !important;
    }
    .header h2{
        font-weight: 500;
    }
    .mt-5{
        margin-top: 2rem !important;
    }
    .py-4{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .modal{
        overflow: auto;
    }
    .content-title.lg{
        font-size: 14px;
    }
    .list-group-item.active:after{
        display: none;
    }
    .list-group-item:last-child{
        border-bottom-right-radius: .25rem;
    }
    .list-group-item:first-child{
        border-top-right-radius: .25rem;
    }
    .change-block.d-flex{
        display: inherit !important;
    }
    .border-orange{
        margin-top: 10px;
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
        left: -120px;
    }
    .nav-pills .nav-link, .select-tab-transaction .title{
        font-size: 11px;
    }
    .select-tab-transaction .nav-link{
        padding: 10px 0 0 0;
        width: 100%;
    }
    .select-tab-transaction img{
        width: 50px;
    }
    .select-tab-transaction .title{
        display: none;
    }
    .profile-thumbnail{
        margin-bottom: 0;
    }
    .profile-head-nav{
        padding-left: 0;
    }
    h1{
        font-size: 30px;
    }
    .index-wrapper{
        /*background: none;*/
    }
    .header{
        margin-bottom: 20px;
    }
    .header img{
        width: 100px;
    }
    .bg-wrapper .left{
        margin-right: inherit;
    }
    .bg-wrapper .right .position{
        min-height: inherit;
        width: inherit;
        position: inherit;
        right: inherit;
        top: inherit;
        bottom: inherit;
        margin: 0 auto 30px auto;
    }
    .bg-wrapper{
        width: 100%;
        border-radius: 0;
        padding: 3% 3% 0 3%;
        background: none;
        box-shadow: none;
    }
}