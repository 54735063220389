.table-info tr>td:first-child {
    width: 25%;
}

.table-row-header {
    background: #ededed;
    border-bottom: 1px solid #ddd;
}
.table-row-even,
.table-row-odd {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    overflow: initial !important;
}
.table-row-even {
    cursor: pointer;
}
.table-row-odd {
    cursor: pointer;
}
.table-row-even.active, .table-row-odd.active {
    color: #000;
    background: #fff4dc;
}
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    outline: none;
}
.ReactVirtualized__Table__headerRow {
    background: #f9f9f9;
    font-size: 11px;
}
.ReactVirtualized__Table__row.active{
  background: #333 !important;
}
.ReactVirtualized__Table__row.active a{
  color: #fff;
}
.ReactVirtualized__Table__row a{
  line-height: 40px;
  display: block;
  color: #337ab7;
  font-weight: bold;

}
.ReactVirtualized__Grid__innerScrollContainer{
    margin-right: 2px;
}
.ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type{
  margin:0;
  padding: 0 10px;
}
.ReactVirtualized__Table__row:nth-child(odd) {
    background: #fff;
}
.ReactVirtualized__Table__row:nth-child(even) {
    background: #f9f9f9;
}
.ReactVirtualized__Table__rowColumn {
    overflow: initial !important;
}
/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24px;
          flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}
