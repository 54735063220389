html, body, div#root, div#root > div:first-child, .main-wrapper, .main-wrapper > div, .index-wrapper {
  height: 100%; }

body {
  font-size: 14px;
  color: #34495e;
  background: #eceef4; }

.modal-title {
  font-size: 1em; }

.ReactModal__Overlay {
  z-index: 999; }

.modal-footer {
  padding: .7em; }

.word-break {
  word-wrap: break-word;
  word-break: break-all; }

.modal-xl {
  max-width: 1024px; }

.table-no-border tr td {
  border: none; }

.ReactModal__Body--open {
  overflow: hidden; }

.ReactModal__Body--open .ReactModal__Overlay--after-open {
  overflow-x: hidden;
  overflow-y: auto; }

.loader-wrapper {
  position: fixed;
  left: 50%;
  z-index: 1051;
  top: 30px; }

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 10% 10% 50% 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.loader:nth-child(1) {
  z-index: 2;
  background: #003aa4;
  border: 2px solid #fff;
  box-shadow: 0 0 0 2px #003aa4;
  animation-name: explode;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: infinite; }

.loader:nth-child(2) {
  background: #fff;
  border: 2px solid #003aa4;
  box-shadow: 0 0 0 2px #fff;
  animation-name: explode;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.5s;
  animation-iteration-count: infinite; }

@keyframes explode {
  0% {
    width: 0;
    height: 0;
    z-index: 1; }
  50% {
    width: 40px;
    height: 50px;
    z-index: 1; }
  100% {
    display: none; } }

.loading-content {
  position: relative;
  top: 50%;
  left: 50%; }
  .loading-content .bullet {
    position: absolute;
    padding: 3.33333px;
    border-radius: 50%;
    background: #cbd7e3;
    animation: animIn 1s ease-in-out 0s infinite; }
    .loading-content .bullet:nth-child(1) {
      animation-delay: 0s; }
    .loading-content .bullet:nth-child(2) {
      animation-delay: 0.15s; }
    .loading-content .bullet:nth-child(3) {
      animation-delay: 0.3s; }
    .loading-content .bullet:nth-child(4) {
      animation-delay: 0.45s; }

@keyframes animIn {
  0% {
    transform: translateX(-100px);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: translateX(100px);
    opacity: 0; } }

a {
  color: #007bff; }

a:hover, a:focus, a:active {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  cursor: hand; }

.font-weight-thin {
  font-weight: 100; }

border {
  border: 1px solid #2c3e50; }

.box-shadow {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }

.br-sm {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px; }

.br-md {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.br-lg {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px; }

hr.light {
  border-top: 1px solid rgba(255, 255, 255, 0.1); }

.container {
  padding-left: 5px;
  padding-right: 5px; }

.row {
  margin-right: -5px;
  margin-left: -5px; }

[class*='col'] {
  padding-left: 5px;
  padding-right: 5px; }

.custom-control-indicator {
  background-color: #eceef4;
  border: 1px solid #c3c3c3;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #007bff; }

.tooltip-wrapper:hover .tooltiptext {
  visibility: visible; }

.arrow_box {
  position: relative;
  background: #88b7d5;
  border: 4px solid #c2e1f5; }

.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #2c3e50;
  border-width: 7px;
  margin-left: -30px; }

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 13px;
  margin-left: -36px; }

.blink {
  animation: blink-animation 2s infinite;
  -webkit-animation: blink-animation 2s infinite; }

@keyframes blink-animation {
  0% {
    background-color: #3A3A3A; }
  50% {
    background-color: #EC2D2D; }
  100% {
    background-color: #3A3A3A; } }

.tip-warning {
  color: #fff;
  font-size: 9px;
  padding: 2px 7px;
  background: #f3af18;
  line-height: 1px;
  border-radius: 10px 10px 10px 0; }

.pagination a {
  padding: 5px 10px;
  border: 1px solid #ddd;
  display: inline-block;
  margin-left: -1px; }

.btn-numbered-page a,
.btn-next-page a,
.btn-prev-more a,
.btn-prev-page a,
.btn-first-page a,
.btn-last-page a,
.btn-next-more a {
  color: #0275d8 !important; }

.btn-numbered-page.active a {
  background-color: #f1f1f1;
  font-weight: bold; }

.pagination .btn-prev-page.disabled a,
.pagination .btn-prev-page.disabled a,
.pagination .btn-first-page.disabled a,
.pagination .btn-next-page.disabled a,
.pagination .btn-last-page.disabled a {
  background-color: #f1f1f1;
  color: #ddd !important; }

.index-wrapper {
  background: #1830A9 url("../img/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.table-wrapper {
  height: 100%;
  width: 100%;
  display: table; }

.table-row {
  height: 100%;
  display: table-row; }

.table-cell {
  vertical-align: middle;
  display: table-cell; }

.inner-wrapper {
  max-width: 900px;
  margin: 0 auto; }

.bg-wrapper {
  float: left;
  width: 100%;
  background-image: linear-gradient(-43deg, #E74C3C 0%, #0031A5 88%);
  box-shadow: 0 6px 14px 0 rgba(0, 16, 54, 0.55);
  color: #FFFFFF;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.bg-wrapper .left {
  margin-right: 470px; }

.bg-wrapper .right .position {
  background: #2C3E50 url(../img/marina_light.png) no-repeat bottom right;
  box-shadow: 0 6px 14px 0 rgba(0, 16, 54, 0.55);
  width: 450px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.bg-wrapper .right .position {
  position: absolute;
  right: 15px;
  top: -35px;
  min-height: 513px; }

.index-wrapper .form-control {
  background: #373737;
  border: 2px solid #868686;
  color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.index-wrapper .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: none; }

.index-wrapper .form-group label {
  display: block;
  color: #fff; }

.index-wrapper .form-control, .btn {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px; }

.top-header {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }

.top-header .navbar {
  padding: .1em; }

.top-header a {
  font-weight: normal;
  color: #eceef4; }

.header {
  text-align: center;
  max-width: 500px;
  margin: 0 auto 50px auto; }

.header img {
  width: 250px; }

.header {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); }

.profile-wrapper {
  background-color: #fff;
  border-left: 1px solid #cad0e2;
  border-right: 1px solid #cad0e2; }

.profile-head-nav {
  padding-left: 185px;
  background-color: #fff;
  border: 1px solid #cad0e2; }

.profile-thumbnail {
  margin-bottom: -55px; }

.profile-head-nav .nav-link {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  border-left: 1px solid #cad0e2;
  border-right: 1px solid #cad0e2;
  margin-left: -1px; }

.profile-head-nav .nav-link.active {
  color: #2c3e50;
  background-color: #fff; }

.inset-arrow, .profile-head-nav .nav-link.active:after, .profile-head-nav .nav-link.active:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  -moz-transform: scale(0.999);
  -webkit-backface-visibility: hidden;
  bottom: -1px; }

.profile-head-nav .nav-link.active:after {
  left: calc(50% - 2px);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #007bff;
  -moz-transform: scale(0.999);
  -webkit-backface-visibility: hidden; }

.profile-head-nav .nav-link.active:before {
  left: calc(50% - 4px);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #007bff; }

.card {
  background-color: #fff; }

.card-header {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #fff; }

.card-footer {
  background-color: #fff; }

.s-alert-box {
  z-index: 1051; }

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.tooltip-wrapper .tooltiptext {
  visibility: hidden;
  width: 250px;
  border: 4px solid #c2e1f5;
  background-color: #2c3e50;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  top: 150%;
  left: 320%;
  margin-left: -340px;
  z-index: 1; }

.tooltip-wrapper:hover .tooltiptext {
  visibility: visible; }

.arrow_box {
  position: relative;
  background: #88b7d5;
  border: 4px solid #c2e1f5; }

.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #2c3e50;
  border-width: 7px;
  margin-left: -30px; }

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 13px;
  margin-left: -36px; }

.tooltip-wrapper-info {
  position: relative;
  display: inline-block; }

.tooltip-info {
  z-index: 9999;
  border-radius: 5px;
  position: absolute;
  background-color: #fff;
  display: block;
  min-width: 200px;
  bottom: 32px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #2c3e50; }

.tooltip-info:after, .tooltip-info:before {
  top: 100%;
  left: calc(0% + 20px);
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.tooltip-info:after {
  border-color: none;
  border-top-color: #fff;
  border-width: 5px;
  margin-left: -5px; }

.tooltip-info:before {
  border-color: none;
  border-top-color: #2c3e50;
  border-width: 6px;
  margin-left: -6px; }

.dropdown-menu {
  padding: 0;
  overflow: hidden; }

.dropdown-menu a {
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.nav-pills .nav-link {
  border-radius: 0;
  border-bottom: 2px solid #fff; }

.nav-pills .nav-link.active {
  font-weight: bold;
  border-bottom-color: #007bff; }

.nav-pills .nav-link.disabled {
  background-color: #ccc;
  color: #949494; }

.nav-pills .nav-link.disabled img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

.nav-pills .nav-link.disabled:hover {
  cursor: not-allowed; }

.custom-control-indicator {
  background-color: #eceef4;
  border: 1px solid #c3c3c3;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  border-color: #007bff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #9ab1c7; }

.Select-menu-outer {
  z-index: 5; }

.rbc-event {
  background: #007bff; }

.rbc-event.rbc-selected {
  background: #2c3e50; }

button {
  cursor: pointer; }

.btn-group-sm > .btn, .btn-sm {
  font-size: 11px; }

.btn {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }

.btn:hover {
  cursor: pointer;
  cursor: hand; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #503ce9;
  border-color: #4833e8; }

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(121, 106, 238, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #503ce9;
  background-image: none;
  border-color: #4833e8; }

.btn-secondary {
  color: #007bff;
  background-color: #ECEFF1;
  border-color: #ECEFF1; }

.btn-secondary:hover {
  color: #007bff;
  background-color: #E2E1EC;
  border-color: #E2E1EC; }

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #ECEFF1;
  border-color: #ECEFF1; }

.btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #007bff;
  background-color: #ECEFF1;
  background-image: none;
  border-color: #ECEFF1; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  background-image: none;
  border-color: #17a2b8; }

.btn-success {
  color: #fff;
  background-color: #33BE25;
  border-color: #33BE25; }

.btn-success:hover {
  color: #fff;
  background-color: #33BE25;
  border-color: #33BE25; }

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  background-color: #33BE25;
  border-color: #33BE25; }

.btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #33BE25;
  background-image: none;
  border-color: #33BE25; }

.btn-warning {
  color: #fff;
  background-color: #FF8000;
  border-color: #FF8000; }

.btn-warning:hover {
  color: #fff;
  background-color: #FF8000;
  border-color: #FF8000; }

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #FF8000;
  border-color: #FF8000; }

.btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #FF8000;
  background-image: none;
  border-color: #FF8000; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-primary {
  color: #007bff;
  background-image: none;
  background-color: transparent;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(121, 106, 238, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc; }

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ccc;
  background-color: transparent; }

.btn-outline-secondary:active, .btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc; }

.btn-outline-info {
  color: #17a2b8;
  background-image: none;
  background-color: transparent;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:active, .btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-success {
  color: #33BE25;
  background-image: none;
  background-color: transparent;
  border-color: #33BE25; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #33BE25;
  border-color: #33BE25; }

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #33BE25;
  background-color: transparent; }

.btn-outline-success:active, .btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #33BE25;
  border-color: #33BE25; }

.btn-outline-warning {
  color: #FF8000;
  background-image: none;
  background-color: transparent;
  border-color: #FF8000; }

.btn-outline-warning:hover {
  color: #fff;
  background-color: #FF8000;
  border-color: #FF8000; }

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FF8000;
  background-color: transparent; }

.btn-outline-warning:active, .btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #FF8000;
  border-color: #FF8000; }

.btn-outline-danger {
  color: #dc3545;
  background-image: none;
  background-color: transparent;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:active, .btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

@media (max-width: 375px) {
  .btn {
    padding: .4rem .50rem;
    font-size: 10px; } }

.no-content i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 15px;
  border-radius: 50%;
  font-size: 40px;
  color: #9aadbf;
  display: inline-block;
  background-color: #eceef4;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }

.no-content h6 {
  font-weight: 100; }

.ReactVirtualized__Table__Grid:focus {
  outline: none; }

.list-group-item {
  color: #2c3e50;
  padding: 5px 10px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2); }
  .list-group-item .req {
    font-size: 16px;
    color: red;
    margin-left: 2px; }

.list-group-item.active {
  background-color: #fd871f;
  border-color: #fd871f;
  position: relative; }
  .list-group-item.active .req {
    color: #fff; }

.list-group-item.active .btn {
  background-color: #fff;
  color: #FF8000; }

.list-group-item.active:after {
  right: -7px;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px; }

.list-group-item:first-child {
  border-top-right-radius: 0; }

.list-group-item:last-child {
  border-bottom-right-radius: 0; }

.reqs-wrapper {
  position: relative;
  overflow: hidden; }

.reqs-title {
  position: absolute;
  width: 100%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: #fff;
  top: 0;
  padding: 10px 10px 20px 10px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.9);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.9)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 ); }

.border-orange {
  border: 5px solid #fd871f;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.slider-arrows.left,
.slider-arrows.right {
  position: absolute;
  top: 0;
  width: 50%;
  bottom: 0;
  z-index: 1;
  font-size: 35px;
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
  color: #fff; }

.slider-arrows.left span,
.slider-arrows.right span {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  display: block;
  width: 100%;
  zoom: 1; }

.slider-arrows.left {
  text-align: left;
  left: 0; }
  .slider-arrows.left:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.2);
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0.2)), color-stop(39%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0) 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 39%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 ); }

.slider-arrows.right {
  text-align: right;
  right: 0; }
  .slider-arrows.right:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.2) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(61%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.2)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.2) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.2) 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.2) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 ); }

.slider-thumbnail-wrapper a {
  filter: grayscale(1); }
  .slider-thumbnail-wrapper a.active {
    filter: grayscale(0); }

.reqs-height {
  height: calc(100% - 84px); }

.reqs-iframe iframe {
  height: inherit !important; }

.loading-box {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0; }
  .loading-box.reversed li {
    border: 3px solid white;
    animation: LOADINGREV 2s infinite; }
    .loading-box.reversed li:nth-child(1n) {
      animation-delay: 0s; }
    .loading-box.reversed li:nth-child(2n) {
      animation-delay: 0.2s; }
    .loading-box.reversed li:nth-child(3n) {
      animation-delay: 0.4s; }
  .loading-box li {
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    margin: 0;
    height: 15px;
    width: 15px;
    border: 3px solid #2c3e50;
    border-radius: 100%;
    transform: transformZ(0);
    animation: LOADING 1s infinite; }
    .loading-box li:nth-child(1n) {
      left: -20px;
      animation-delay: 0s; }
    .loading-box li:nth-child(2n) {
      left: 0;
      animation-delay: 0.2s; }
    .loading-box li:nth-child(3n) {
      left: 20px;
      animation-delay: 0.4s; }

@keyframes LOADING {
  0% {
    transform: scale(0.5);
    background: #2c3e50; }
  50% {
    transform: scale(1);
    background: white; }
  100% {
    transform: scale(0.5);
    background: #2c3e50; } }

@keyframes LOADINGREV {
  0% {
    transform: scale(0.5);
    background: white; }
  50% {
    transform: scale(1);
    background: #2c3e50; }
  100% {
    transform: scale(0.5);
    background: white; } }

.badge {
  font-size: 11px;
  font-weight: normal; }

.select-tab-transaction li {
  width: 25%; }

.select-tab-transaction li a {
  border: 1px solid #eceef4; }

.select-tab-transaction li a.active {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }

.select-tab-transaction .icon {
  width: 80px; }

.select-tab-transaction .title {
  font-size: 11px; }

.or-span {
  display: block;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E8E8E8;
  line-height: 0.1em;
  margin: 20px 0;
  opacity: .3; }

.or-span > span {
  font-size: 10px;
  background: #2c3e50;
  padding: 0 5px; }

.payment-channel img {
  height: 50px;
  width: 50px; }

.title-line {
  position: relative;
  color: #00a062; }

.title-line:before {
  border-top: 4px solid #d1eadd;
  content: "";
  margin: 0 auto;
  /* this centers the line to the full width specified */
  position: absolute;
  /* positioning must be absolute here, and relative positioning must be applied to the parent */
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1; }

.title-line span {
  /* to hide the lines from behind the text, you have to set the background color the same as the container */
  background: #fff;
  line-height: 23px; }

.title-line span:last-child {
  padding-right: 0; }

.title-line span:first-child {
  padding-right: 10px; }

.trans-text-success {
  font-family: "Monaco", "DejaVu", "Sans Mono", "OCR-A", "OCR-B";
  -webkit-box-shadow: inset 0 0 0 3px white;
  box-shadow: inset 0 0 0 3px white;
  border: 2px solid #35dc9e;
  color: #35dc9e;
  background-color: #e2ffde;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.trans-text {
  font-family: "Monaco", "DejaVu", "Sans Mono", "OCR-A", "OCR-B";
  box-shadow: inset 0 0 0 3px white;
  border: 2px solid #dc3545;
  color: #dc3545;
  background-color: #ffdee1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px; }

.font-receipt {
  font-family: "Monaco", "DejaVu", "Sans Mono", "OCR-A", "OCR-B"; }

.barcode-wrapper {
  margin: 0; }

.select-transaction-details {
  border: 3px solid #007bff; }

.select-tab-transaction .nav-link {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.card-height-static {
  max-height: 730px;
  overflow-y: auto; }

.appointment {
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 11px;
  border-style: solid;
  border-width: 1px;
  border-color: #0bc26c;
  color: #0bc26c;
  background-color: #f3fff9; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }
